import React from 'react';
import '../styles/microbenefit.scss';

export default function MicroBenefit(props) {
	return (
		<div
			className='microbenefit_columns'
			data-sal='flip-up'
			data-sal-delay='200'
			data-sal-easing='cubic-bezier(0.25, 0.1, 0, 2.05)'
		>
			{!!props.imageUrl && (
				<div className='microbenefit_column mibrobenefit_image'>
					<img src={props.imageUrl} alt='Beneficio' />
				</div>
			)}
			{!props.imageUrl && (
				<div className='microbenefit_column mibrobenefit_imageT'>
					<span>{props.number}</span>
				</div>
			)}
			<div className='microbenefit_column microbenefit_text'>
				{props.children}
			</div>
		</div>
	);
}
