import React from 'react';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.en';
import SolutionsBlockEN from '../components/solutionsblock.component.en';
import MicroBenefit from '../components/microbenefit.component';
import BottomCTA from '../components/bottomCTA.component.en';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';
import '../styles/product.scss';
import introProductImage from '../static/images/kuflow-design-science.gif';

export default function Product() {
	return (
		<LayoutEN>
			<Seo title='In detail' />
			<section id='introFAQ'>
				<div className='introFAQ-columns'>
					<div id='introFAQ-text' class='introProduct-column'>
						<img
							style={{ maxWidth: '240px' }}
							src={introProductImage}
							alt='Pro workflows'
						/>
						<h1>KuFlow in detail</h1>
						<h2>KuFlow explained to you like you're NOT 5 years old</h2>
					</div>
				</div>
			</section>
			<section id='productFAQ' style={{ marginBottom: '1rem' }}>
				<div className='benefits'>
					<MicroBenefit number='1'>
						<h3>What is KuFlow</h3>
						<p>
							KuFlow is a{' '}
							<b>
								developer-oriented workflow engine, on which to design and
								execute business processes
							</b>
							. We like to call it an orchestrator of tasks, both human and
							automated.
						</p>
						<ul>
							<li>
								In KuFlow you define a business process as a set of interrelated
								tasks,
							</li>
							<li>
								KuFlow has a web interface to be able to define the processes
								and so that the users can interact with each human task (filling
								out a form, approving requests, validating the results of an
								automatic task).
							</li>
							<li>
								The KuFlow engine is responsible for executing the flow of
								tasks, robustly and persistently. It automatically handles
								retries and timeouts for you.
							</li>
							<li>
								The content of each task can be very diverse and is always
								defined in code.
							</li>
						</ul>
					</MicroBenefit>
					<MicroBenefit number='2'>
						<h3>What is NOT KuFlow</h3>
						<ul>
							<li>
								It <b>is not a BPM tool or a No-Code tool</b>. We do not use
								boxes and arrows to define business processes. Instead, KuFlow
								is based on code.
							</li>
							<li>
								It{' '}
								<b>
									is not a tool to replace software solutions such as HR or
									Accounting
								</b>
								. Instead, KuFlow is a cross-functional tool for building
								business processes for any department or across departments.
							</li>
						</ul>
					</MicroBenefit>
				</div>
				<Zoom>
					<img
						src='/images/kuflow_screenshot_processlist_01_EN.png'
						width='600'
						style={{ margin: '0 auto' }}
						alt='Processes list'
					/>
				</Zoom>
				<div className='benefits'>
					<MicroBenefit number='3'>
						<h3>Why KuFlow isn't a LowCode tool</h3>
						<p>
							After more than 15 years of developing custom software for
							companies, the KuFlow team has understood that business processes
							are sometimes simply too complex to approach with a Low-Code tool.
							<b>Sometimes the reality is not that simple</b>. Doing a workflow
							through code requires more in-depth elaboration and qualified
							personnel, but it allows you as much versatility as possible and
							can cover any level of complexity.
						</p>
						<p>
							<b>KuFlow is built by developers for developers.</b>
						</p>
					</MicroBenefit>
					<MicroBenefit number='4'>
						<h3>Who is KuFlow intended for</h3>
						<p>
							KuFlow makes sense for <b>organizations of a certain size</b>,
							with two fundamental aspects:
						</p>
						<ul>
							<li>
								They have a{' '}
								<b>certain complexity in their business processes</b>, which
								they must try to solve and automate as much as possible.
							</li>
							<li>
								They <b>have an IT department</b> that must respond to and
								support the digital transformation needs of the organization.
							</li>
						</ul>
					</MicroBenefit>
				</div>
				<div className='use-cases'>
					<h2>What can you do with KuFlow</h2>
					<div className='use-cases-columns'>
						<div className='use-case-column'>
							<h3>Implement workflows in companies</h3>
							<ul>
								<li>
									<b>Pretty much anything you can think of</b>:
								</li>
								<ul>
									<li>
										Purchase approval, from the initial request to contacting
										the supplier and storing the invoice.
									</li>
									<li>Request vacations in the company.</li>
									<li>Receive customer feedback and reuse that information.</li>
									<li>
										Manage the relationship with the client from the initial
										proposal to the delivered product.
									</li>
									<li>
										Automatically remind clients of pending documents or
										authorizations.
									</li>
									<li>Review a website every X time and prepare a report.</li>
								</ul>
								<li>
									In essence, if you can think of it as a flowchart, you can do
									it.
								</li>
								<li>
									For more information about this case, please visit{' '}
									<a href='https://kuflow.com/en/solution-workflow'>
										this link
									</a>
									.
								</li>
							</ul>
						</div>
						<div className='use-case-column'>
							<h3>Orchestration and coordination of RPA solutions</h3>
							<ul>
								<li>
									You can <b>implement RPA through tools like RobotFramework</b>
									.
								</li>
								<li>
									KuFlow will take care of making the process resilient,
									managing retries, managing timeouts, managing asynchronous
									processes, and storing the states of each stage.
								</li>
								<li>
									For more information about this case, please visit{' '}
									<a href='https://kuflow.com/en/solution-rpa'>this link</a>.
								</li>
							</ul>
						</div>
						<div className='use-case-column'>
							<h3>Service orchestration</h3>
							<ul>
								<li>
									<b>Any type of service orchestration or any data pipeline</b>{' '}
									can benefit from the robustness provided by the KuFlow engine:
									retries, timeouts, and so on.
								</li>
								<li>
									For more information about this case, please visit{' '}
									<a href='https://kuflow.com/en/solution-orchestration/'>
										this link
									</a>
									.
								</li>
							</ul>
						</div>
					</div>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_RPAdetails_01_ES.png'
							width='600'
							style={{ margin: '0 auto' }}
							alt='RPA reintentando'
						/>
					</Zoom>
				</div>
				<div className='benefits'>
					<MicroBenefit number='5' color='orange'>
						<h3>How it works</h3>
						<p>
							Broadly speaking, once a user account and organization have been
							created, the business processes are implemented as follows:
						</p>
						<ul>
							<li>
								<b>A process and its tasks are defined</b>, registering all the
								information in KuFlow through the web.
							</li>
							<li>
								<b>Permissions are defined</b> for those users who can start
								processes or perform certain tasks.
							</li>
							<li>
								<b>The Workflow Worker</b> is implemented through code.
							</li>
							<li>
								<b>The Workflow Worker is deployed</b> on your own
								infrastructure and KuFlow will act as the orchestration engine
								at each step of the process.
							</li>
						</ul>
						<p>
							The architecture at a technical level is better explained in our{' '}
							<a href='https://docs.kuflow.com/developers/architecture'>
								dev docs
							</a>
							.
						</p>
					</MicroBenefit>
					<MicroBenefit number='6'>
						<h3>How can I test the tool?</h3>
						<p>
							KuFlow has a <b>free use layer</b>, which includes all the
							functionalities and its only restrictions are the number of agents
							and the space used by each organization. You can{' '}
							<a href='http://app.kuflow.com/'>register here</a>.
						</p>
						<p>
							There are many ways to get started with KuFlow, but we thought a
							tutorial might be very intuitive.
						</p>
						<p>
							We have implemented the use case of a loan application to
							contemplate human tasks (application and approval), decision flows
							(based on the amount of the loan, it is granted without the need
							for approval), and certain automatic tasks (such as the conversion
							of currency). The tutorial is available in{' '}
							<a href='https://www.youtube.com/watch?v=GUPRV7UOpr0'>
								this video
							</a>
							&nbsp;and all the documentation, including the code, is in{' '}
							<a href='https://docs.kuflow.com/developers/examples/curated/java/java-loantutorial'>
								this link
							</a>
							.
						</p>
					</MicroBenefit>
					<MicroBenefit number='7'>
						<h3>What's next?</h3>
						<p>
							We continue to work on KuFlow with various features, such as a
							diagram tool for serverless workflows, analytics features, and
							building infrastructure for workers to run on the KuFlow Cloud.
						</p>
						<p>
							We are in an early stage of development,{' '}
							<b>
								and we are totally open to suggestions and feedback from the
								community
							</b>
							.
						</p>
					</MicroBenefit>
				</div>
			</section>
			<SolutionsBlockEN />
			<BottomCTA />
		</LayoutEN>
	);
}
